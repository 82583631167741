import React, { useEffect, useMemo, useState } from "react";
import { graphql } from "gatsby";
import { PageContext } from "../store/context/page-context";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/course-items.png";
import Navigation from "../components/common/webinars/Navigation";
import Courses from "../components/courses-templates/Courses";
import QuestionnaireContainer from "../containers/common/QuestionnaireContainer";
import CheckLicenseController from "../controllers/webinars/checkLicenseController";

const checkLicenseController =
  typeof window !== "undefined" && new CheckLicenseController();

const TeCT = ({ data, pageContext }) => {
  const {
    title,
    metaTitle,
    metaKeywords,
    metaImage,
    h1,
    cta,
    paidItems,
    freeItems,
    cnItems,
    metaDescription,
    slug,
    navigationItems,
  } = pageContext;

  const [getPlayerState, setPlayerState] = useState({
    isShowYoutubeModal: false,
    urlYoutubeModal: "",
    isLicense: false,
    isOpenPricing: false,
    isOpenQuestionnaire: false,
    isCnUser: false,
    isMobile: false,
  });

  const updatePlayerState = (key, value) => {
    setPlayerState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  const handlerOpenQuestionnaire = (status) => {
    updatePlayerState("isOpenQuestionnaire", status);
  };
  useEffect(() => {
    checkLicenseController.checkLicense(updatePlayerState);
  }, []);

  useEffect(() => {
    updatePlayerState("isShowYoutubeModal", !!getPlayerState.urlYoutubeModal);
  }, [getPlayerState.urlYoutubeModal]);

  const closeYoutubeModal = () => updatePlayerState("urlYoutubeModal", "");
  const closePricing = () => updatePlayerState("isOpenPricing", false);
  const handleResize = () => {
    updatePlayerState(
      "isMobile",
      window.matchMedia("(max-width: 991px)").matches
    );
  };
  const handleKeyEsc = (e) => {
    if (e.keyCode === 27) {
      closeYoutubeModal();
    }
  };
  useEffect(() => {
    handleResize();
  }, [getPlayerState.isMobile]);

  useEffect(() => {
    window.addEventListener("resize", () => handleResize());
    document.addEventListener("keydown", handleKeyEsc);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("keydown", handleKeyEsc);
    };
  }, []);

  const navigationPanel = useMemo(
    () => (
      <Navigation
        mainTitle={cta}
        items={navigationItems}
        currentPage={slug}
        isBlueView
      />
    ),
    []
  );
  return (
    <PageContext.Provider
      value={{
        handlerOpenQuestionnaire,
      }}
    >
      <Layout
        isAbsoluteHeader
        urlYoutubeModal={getPlayerState.urlYoutubeModal}
        isShowYoutubeModal={getPlayerState.isShowYoutubeModal}
        closeYoutubeModal={closeYoutubeModal}
        isOpenPricing={getPlayerState.isOpenPricing}
        closePricing={closePricing}
        isShowSurveyButton
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          keywords={metaKeywords}
          description={metaDescription}
          page={slug}
          manifest="browserconfig.xml"
          ogImg={metaImage ? metaImage.file.url : OgImg}
        />
        <QuestionnaireContainer
          slug={slug}
          isOpen={getPlayerState.isOpenQuestionnaire}
          handlerOpenQuestionnaire={handlerOpenQuestionnaire}
        />
        {navigationPanel}
        <Courses
          sectionDescriptions={data.allContentfulSectionDescription.edges}
          items={data.allContentfulCoursesItem.edges}
          getPlayerState={getPlayerState}
          paidList={paidItems}
          freeList={freeItems}
          cnList={cnItems}
          updatePlayerState={updatePlayerState}
          h1={h1}
        />
      </Layout>
    </PageContext.Provider>
  );
};

export const pageQuery = graphql`
  query coursePageQuery($sectionDescriptionId: String) {
    allContentfulCoursesItem {
      edges {
        node {
          id
          title
          lesson
          paid
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          videoUrl
          description {
            raw
          }
          button
          blockingButton
          timecodeDescriptionList
          timecodeList
        }
      }
    }
    allContentfulSectionDescription(
      filter: { id: { eq: $sectionDescriptionId } }
    ) {
      edges {
        node {
          id
          freeDescription {
            raw
          }
          paidDescription {
            raw
          }
          cnDescription {
            raw
          }
        }
      }
    }
  }
`;

export default TeCT;
