import React from "react";
import PropTypes from "prop-types";
import { timeInSeconds } from "../../../helpers";

const updateVideoUrl = (str = "0:00", url) =>
  `${url}${url.includes("?") ? "&" : "?"}start=${timeInSeconds(str, ":")}`;

const TimecodeList = ({ data, end, handleOnClick, isPaid, videoUrl }) => {
  const listTimecode = [];
  for (let i = 0; i < end; i++) {
    const url = updateVideoUrl(data.timecodeList[i], videoUrl);
    listTimecode.push(
      <li key={i} onClick={(e) => handleOnClick(e, isPaid, url, data.title)}>
        <span>{data.timecodeList[i]}</span>
        <p>{data.timecodeDescriptionList[i]}</p>
      </li>
    );
  }
  return <ul>{listTimecode}</ul>;
};
TimecodeList.propTypes = {
  data: PropTypes.object.isRequired,
  videoUrl: PropTypes.string,
  isPaid: PropTypes.bool,
  end: PropTypes.number,
};
export default TimecodeList;
