import React, { useState } from "react";
import PropTypes from "prop-types";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import VideoItem from "../../../common/webinars/VideoItem";
import TimecodeList from "../TimecodeList";
import "./index.scss";

const CourseItem = ({
  data,
  isLicense,
  handleOnClick,
  isCn,
  isWaMode,
  isMobile,
  onShowMoreOrLessClick,
}) => {
  const [isShowMore, setIsShowMore] = useState(true);

  const isPaid = isLicense ? false : data.paid;

  const options = {
    renderNode: {
      // [BLOCKS.PARAGRAPH]: (node, children) => <p className='CourseItem-info__description'>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="CourseItem-list">{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="CourseItem-list__item">
          {children[0].props.children[0]}
        </li>
      ),
      [INLINES.HYPERLINK]: (node, children) =>
        isPaid ? (
          <span className="CourseItem__replacementText">
            Select a plan to get full access to the Extended course!
          </span>
        ) : (
          <a href={node.data.uri} target={"_blank"}>
            {children}
          </a>
        ),
    },
  };
  const checkUrl = () => {
    const arr = data.videoUrl.split("?");
    const listParameters = [];

    if (arr.length > 1) {
      const parameters = arr[1].split("&");

      parameters.forEach((str) => {
        if (str.includes("listType=") || str.includes("list=")) {
          listParameters.push(str);
        }
      });
    }
    listParameters.push("modestbranding=1");
    listParameters.push("autoplay=1");

    const checkedParameters = listParameters.filter(Boolean).join("&");

    return `${arr[0]}?${checkedParameters}`;
  };
  const videoUrl = isCn ? data.videoUrl : checkUrl();

  const handleOnClickVideo = (e) => {
    handleOnClick(e, isPaid, videoUrl, data.title);
  };
  const classes = ["CourseItem", !!isCn && "CourseItem__cnPage"]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={classes}>
      <VideoItem
        adClassName="CourseItem-image"
        imageData={data.image}
        isWaMode={isWaMode}
        lesson={data.lesson}
        onLessonIcon={!isCn}
        decorationForPaid={data.paid}
        handleOnClick={handleOnClickVideo}
        offLazyLoad
      />
      <div className="CourseItem-info">
        <p className="CourseItem-info__title" onClick={handleOnClickVideo}>
          {data.title}
        </p>
        <div
          className="CourseItem-info__description"
          onClick={handleOnClickVideo}
        >
          {renderRichText(data.description, options)}
        </div>
        {!!data.timecodeList && (
          <div className="CourseItem-info-timeLine">
            <TimecodeList
              data={data}
              end={isShowMore ? (isMobile ? 3 : 5) : data.timecodeList.length}
              handleOnClick={handleOnClick}
              isPaid={isPaid}
              videoUrl={videoUrl}
            />
            {data.timecodeList.length > (isMobile ? 3 : 5) && (
              <div
                className="CourseItem-info-timeLine__btn"
                onClick={() => {
                  setIsShowMore(!isShowMore);
                  onShowMoreOrLessClick();
                }}
              >
                {isShowMore ? "show more" : "show less"}
              </div>
            )}
          </div>
        )}
        {isCn && (
          <button
            className="CourseItem-info__button"
            onClick={handleOnClickVideo}
          >
            {isPaid ? data.blockingButton : data.button}
          </button>
        )}
      </div>
    </div>
  );
};

CourseItem.propTypes = {
  data: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  isLicense: PropTypes.bool.isRequired,
  isCn: PropTypes.bool,
  isWaMode: PropTypes.bool,
  isMobile: PropTypes.bool,
  onShowMoreOrLessClick: PropTypes.func,
};

export default CourseItem;
