import React, { useMemo, useEffect } from "react";
import Container from "../../common/Container";
import CourseItem from "./CourseItem";
import "./index.scss";
// import CoursesTitle from "./CoursesTitle";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import PropTypes from "prop-types";
import AnalyticsController from "../../../controllers/webinars/analyticsController";
import WaModeController from "../../../controllers/webinars/waModeController";
import CheckLicenseController from "../../../controllers/webinars/checkLicenseController";

const analyticsController =
  typeof window !== "undefined" && new AnalyticsController();
const waModeController =
  typeof window !== "undefined" && new WaModeController();
const checkLicenseController =
  typeof window !== "undefined" && new CheckLicenseController();

// const options = {
//   renderMark: {
//     [MARKS.BOLD]: (text) => <span>{text}</span>,
//   },
//   renderNode: {
//     [BLOCKS.PARAGRAPH]: (node, children) => (
//       <CoursesTitle>{children}</CoursesTitle>
//     ),
//   },
// };

const titleOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => text,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      node.content[0].marks[0]?.type === "bold" ? children : null,
  },
};
const Courses = ({
  items,
  paidList,
  freeList,
  cnList,
  updatePlayerState,
  getPlayerState,
  sectionDescriptions,
  h1,
  getRouteState,
  onMount,
}) => {
  useEffect(() => {
    onMount && onMount();
  }, [getRouteState?.slug]);

  const currentDescription =
    sectionDescriptions.length > 0 ? sectionDescriptions[0] : null;

  const checkTagA = (e) => {
    const tegA = e?.target.tagName === "A";
    const parentTegA = e?.target.parentElement.tagName === "A";
    const text = e?.target.innerText ?? "";
    let href = tegA ? e?.target.href : "";
    href = parentTegA ? e?.target.parentElement.href : href;

    return { isTagA: tegA || parentTegA, text, href };
  };

  const handleOnClick = (e, isPaid, videoUrl, title) => {
    const { isTagA, text, href } = checkTagA(e);

    !isTagA && analyticsController.sendClickCourseEvent(title);
    checkLicenseController.checkLicense(updatePlayerState);
    if (isPaid) {
      getPlayerState.isWaMode
        ? waModeController.blockPaidContent(getPlayerState.heightPage)
        : updatePlayerState("isOpenPricing", true);
    } else {
      isTagA
        ? analyticsController.sendClickCourseEventTegA(href, text)
        : getPlayerState.isWaMode
        ? waModeController.unblockPaidContent(videoUrl)
        : updatePlayerState("urlYoutubeModal", videoUrl);
    }
  };

  const handleShowMoreOrLessClick = () => {
    getPlayerState.isWaMode && waModeController.unblockPaidContent(null);
  };

  const getCoursesFromQuery = (isFree, isCn) => {
    const enList = isFree ? freeList : paidList;
    const list = isCn ? cnList : enList;
    if (!list) return null;
    return (
      <div
        className={`Courses-content__video Courses-content__video_${
          isFree ? "free" : "paid"
        }`}
      >
        <p className="Courses-content__title">
          {renderRichText(
            isCn
              ? currentDescription.node.cnDescription
              : isFree
              ? currentDescription.node.freeDescription
              : currentDescription.node.paidDescription,
            titleOptions
          )}
        </p>
        <div className="Courses-content__line" />
        <div className="Courses-content__items">
          {list.length > 0 &&
            list.map((objId, index) => {
              const item = items.find(
                (e) =>
                  e.node.id === objId.id &&
                  (isFree ? !e.node.paid : e.node.paid)
              );
              return (
                <CourseItem
                  data={item.node}
                  key={index}
                  isLicense={getPlayerState.isLicense}
                  handleOnClick={handleOnClick}
                  isCn={isCn}
                  isWaMode={getPlayerState.isWaMode}
                  isMobile={getPlayerState.isMobile}
                  onShowMoreOrLessClick={handleShowMoreOrLessClick}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const freeCourses = useMemo(
    () => getCoursesFromQuery(true, false),
    [
      getRouteState?.slug,
      getPlayerState.isLicense,
      getPlayerState.isWaMode,
      getPlayerState.heightPage,
      getPlayerState.isMobile,
    ]
  );

  const paidCourses = useMemo(
    () => getCoursesFromQuery(false, false),
    [
      getRouteState?.slug,
      getPlayerState.isLicense,
      getPlayerState.isWaMode,
      getPlayerState.heightPage,
      getPlayerState.isMobile,
    ]
  );

  const cnCourses = useMemo(
    () => getCoursesFromQuery(true, true),
    [
      getRouteState?.slug,
      getPlayerState.isLicense,
      getPlayerState.isWaMode,
      getPlayerState.isCnUser,
      getPlayerState.heightPage,
      getPlayerState.isMobile,
    ]
  );

  return (
    <section className="Courses">
      <Container webSize>
        <h1>{h1}</h1>
        {getPlayerState.isCnUser && cnCourses}
        {freeCourses}
        {paidCourses}
      </Container>
    </section>
  );
};

Courses.prototype = {
  items: PropTypes.object.isRequired,
  getPlayerState: PropTypes.object.isRequired,
  updatePlayerState: PropTypes.func.isRequired,
  sectionDescriptions: PropTypes.array,
  paidList: PropTypes.array.isRequired,
  freeList: PropTypes.array.isRequired,
  cnList: PropTypes.array.isRequired,
  getRouteState: PropTypes.object,
  onMount: PropTypes.func,
};

export default Courses;
